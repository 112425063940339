<template>
  <div>
    <template v-for="(route, index) in permission_routes">
      <!--只有一级菜单-->
      <el-menu-item
        v-if="route.children == null && route.meta.menuShow"
        :data="route"
        :index="route.path"
        :key="route.path + index"
      >
        <i :class="route.meta.icon"></i>
        <span slot="title">{{ route.meta.title }}</span>
      </el-menu-item>

      <!--有子菜单-->
      <el-submenu
        :key="route.path + index"
        :index="route.path + index"
        v-if="route.children && route.meta.menuShow"
      >
        <template slot="title">
          <i :class="route.meta.icon"></i>
          <span>{{ route.meta.title }} </span>
        </template>
        <sidebar-item :permission_routes="route.children"></sidebar-item>
      </el-submenu>
    </template>
    <!--</template>-->
  </div>
</template>

<script>
export default {
  name: "sidebar-item",
  props: {
    permission_routes: {
      type: Array
    }
  },
  mounted() {
    // console.log(this.permission_routes)
  }
};
</script>

<style scoped></style>
