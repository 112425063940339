import { getToken, setToken } from "@/util/token";

const state = {
  sidebar: {
    opened: JSON.parse(getToken("opened"))
      ? JSON.parse(getToken("opened"))
      : true,
    withoutAnimation:
      JSON.parse(getToken("withoutAnimation")) === null ||
      JSON.parse(getToken("withoutAnimation")) === false
        ? JSON.parse(getToken("withoutAnimation"))
        : false
  },
  device: "desktop"
};

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    setToken("opened", JSON.stringify(state.sidebar.opened));
    setToken("withoutAnimation", false);

    if (state.sidebar.opened) {
      // Cookies.set('sidebarStatus', 1)
    } else {
      // Cookies.set('sidebarStatus', 0)
    }
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  }
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
