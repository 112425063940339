import Vue from "vue";
import App from "./App.vue";

// import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import "./assets/styles/element-variables.scss";

import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/ruoyi.scss"; // ruoyi css
import "@/assets/styles/business.scss";
import "@/assets/styles/element-ui.scss";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/styles/index.scss"; // global css
Vue.use(ElementUI);

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

import moment from 'moment'
Vue.prototype.$moment = moment;//赋值使用
moment.locale('zh-cn');//需要汉化

import config from "./config/index";
import { get, post, postForm } from "./axios/api";

Vue.prototype.config = config;
Vue.prototype.get = get;
Vue.prototype.post = post;
Vue.prototype.postForm = postForm;
Vue.config.productionTip = false;

Vue.prototype.msgSuccess = function(msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
};

Vue.prototype.msgError = function(msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
};

Vue.prototype.msgInfo = function(msg) {
  this.$message.info(msg);
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
