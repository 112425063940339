import Layout from "@/views/layout";

export default [
	{
		path: "/",
		meta: {
			menuShow: true,
			title: "首页",
			icon: "el-icon-s-home"
		},
		redirect: "/notice",
		component: Layout,
		children: [
			{
				path: "/notice",
				name: "notice",
				meta: {
					menuShow: true,
					title: "授权记录",
					icon: "el-icon-edit-outline"
				},
				component: () => import("@/views/notice/index")
			},
			{
				path: "/details/:id",
				name: "details",
				meta: {
					menuShow: false,
					title: "公告回复",
					icon: "el-icon-chat-square"
				},
				component: () => import("@/views/notice/details")
			},
			{
				path: "/village/villageInfo",
				name: "villageInfo",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "授权中心",
					icon: "el-icon-s-cooperation"
				},
				component: () => import("@/views/village/village-info/index")
			},
		]
	},
	/*{
		path: "/village",
		name: "layout",
		redirect: "noRedirect",
		meta: {
			menuShow: true,
			title: "村子管理",
			icon: "el-icon-s-home"
		},
		component: Layout,
		children: [
			{
				path: "/village/villageInfo",
				name: "villageInfo",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "村子信息",
					icon: "el-icon-s-release"
				},
				component: () => import("@/views/village/village-info/index")
			},
			{
				path: "/village/organization",
				name: "organization",
				hidden: false,
				meta: {
					menuShow: true,
					title: "组织管理",
					icon: "el-icon-s-comment"
				},
				component: () => import("@/views/village/organization/index")
			},
			{
				path: "/village/organizationDetails/:id",
				name: "organizationDetails",
				hidden: false,
				meta: {
					menuShow: false,
					title: "查看组织成员",
					icon: "el-icon-s-comment"
				},
				component: () => import("@/views/village/organization/details")
			},
			{
				path: "/village/villager",
				name: "villager",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "村民管理",
					icon: "el-icon-s-claim"
				},
				component: () => import("@/views/village/villager/index")
			},
			{
				path: "/village/audit",
				name: "audit",
				hidden: false,
				meta: {
					menuShow: true,
					title: "我的家乡审核管理",
					icon: "el-icon-s-opportunity"
				},
				component: () => import("@/views/village/audit/index")
			}
		]
	},
	{
		path: "/invitation",
		// hidden: true,
		redirect: "noRedirect",
		alwaysShow: true,
		meta: {
			menuShow: true,
			title: "帖子管理",
			icon: "el-icon-s-order"
		},
		component: Layout,
		children: [
			{
				path: "/invitation/posting",
				name: "posting",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "帖子信息管理",
					icon: "el-icon-s-tools"
				},
				component: () => import("@/views/invitation/posting/index")
			}
		]
	},
	{
		path: "/app",
		alwaysShow: true,
		meta: {
			menuShow: true,
			title: "app管理",
			icon: "el-icon-s-goods"
		},
		component: Layout,
		children: [
			{
				path: "/app/banner",
				name: "banner",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "配置banner",
					icon: "el-icon-picture"
				},
				component: () => import("@/views/app/banner/index")
			},
			{
				path: "/app/appService",
				name: "banner",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "APP服务",
					icon: "el-icon-headset"
				},
				component: () => import("@/views/app/app-service/index")
			}
		]
	},
	{
		path: "/system",
		alwaysShow: true,
		meta: {
			menuShow: false,
			title: "系统管理",
			icon: "el-icon-s-tools"
		},
		component: Layout,
		children: [
			{
				path: "/system/menu",
				name: "banner",
				// hidden: false,
				meta: {
					menuShow: true,
					title: "菜单管理",
					icon: "el-icon-s-grid"
				},
				component: () => import("@/views/system/menu/index")
			}
		]
	}*/
];
