import { MessageBox, Message } from "element-ui";

/**
 * 渲染左侧导航栏处理数据方法
 * @param list
 * @returns {*[]|T[]}
 */
export function routeSidebar(list) {
  let arr = [],
    arrFilter = [],
    permission_routes = [],
    objList = [];
  let obj = {};
  let index;
  permission_routes = list.map((item, index) => {
    if (!item.alwaysShow) {
      if (item.children) {
        if (item.children && item.children.length == 1) {
          obj = item.children[0];
          objList.push({
            obj: item.children[0],
            id: index
          });
          return item;
        } else {
          return item;
        }
      } else {
        return item;
      }
    } else {
      return item;
    }
  });
  arrFilter = permission_routes.filter(data => {
    if (data.children && (data.alwaysShow || data.children.length != 1)) {
      return data;
    }
  });

  permission_routes = [];

  for (let i = 0; i < objList.length; i++) {
    arrFilter.splice(objList[i].id, 0, objList[i].obj);
  }

  return (permission_routes = arrFilter);
}

/**
 * 公共提示信息
 * @param message 提示信息
 * @param title 标题
 * @param callback 回调函数
 * @param id 接口参数
 * @constructor
 */
export function messageBox(message, title, callback, id) {
  MessageBox.confirm(message, title, {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning"
  })
    .then(() => {
      callback(id);
    })
    .catch(() => {
      Message({
        type: "info",
        message: "已取消删除"
      });
    });
}
