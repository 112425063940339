/**
 * 获取token
 * @param key
 * @returns {string}
 */
export function getToken(key) {
  // return window.localStorage.getItem(key);
  return window.sessionStorage.getItem(key);
}

/**
 * 存入token
 * @param key
 * @param value
 */
export function setToken(key, value) {
  // return window.localStorage.setItem(key, value);
  return window.sessionStorage.setItem(key, value);
}
