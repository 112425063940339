import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import { getToken } from "../util/token";
import routes from "./routes";

Vue.use(VueRouter);

/*const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/!* webpackChunkName: "about" *!/ "../views/About.vue")
  }
];*/

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes: routes
});

// 路由前置守卫
router.beforeEach((to, from, next) => {
    // debugger
    const token = getToken("token") ? getToken("token") : null;
    if (to.path == "/login") {
        next();
    } else {
        if (token) {
            next();
        } else {
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        }
    }
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

export default router;
