import variables from "@/assets/styles/element-variables.scss";

const state = {
  theme: variables.theme,
  showSettings: false,
  tagsView: true,
  fixedHeader: false,
  sidebarLogo: true
};

export default {
  namespaced: true,
  state
};
