export default [
  {
    path: "/login",
    name: "login",
    meta: {
      menuShow: false,
      menuName: "登录"
    },
    component: () => import("@/views/login")
  }
];
