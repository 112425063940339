import axios from "axios";
import router from "@/router";
import {Loading, Message, Notification, MessageBox} from "element-ui";
import config from "../config/index";
import {getToken} from "../util/token";
import errorCode from "@/util/errorCode";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.withCredentials = true;
let apiBaseUrl = window._CONFIG['domianURL'] || "/jeecg-boot";

let instance = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: apiBaseUrl,
    timeout: config.defaultTimeout
});

let loading;

function showLoading(message = "加载中...") {
    loading = Loading.service({
        lock: true,
        text: message,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "loadingClassName"
    });
}

function hideLoading() {
    loading.close();
}

instance.interceptors.request.use(
    config => {
        showLoading();
        const token = getToken("token") ? getToken("token") : null;
        if (token) {
            config.headers["X-Access-Token"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        hideLoading();
        if (response.status == 200) {
            if (response.data.code == 502 || response.data.code == 503) {
                router.push({
                    path: "/login"
                });
            }
            return response;
        }
    },
    error => {
        hideLoading();
        // console.log(error);
        // 未设置状态码则默认成功状态
        // const code = error.response.data.status || 200;
        const code = error.response.status || 200;
        // 获取错误信息
        const msg = errorCode[code] || error.response.data.message || errorCode["default"];
        // console.log(msg);
        if (error && error.response) {
            if (code == 401) {
                MessageBox.confirm(
                    "登录状态已过期，您可以继续留在该页面，或者重新登录",
                    "系统提示",
                    {
                        confirmButtonText: "重新登录",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                ).then(() => {
                    // store.dispatch('LogOut').then(() => {
                    // 	location.href = '/index';
                    // })
                    router.push({
                        path: "/login"
                    });
                });
            } else if (code == 500) {
                /*Message({
                    message: msg,
                    type: "error"
                });*/

              MessageBox.confirm(
                  "登录状态已过期，您可以继续留在该页面，或者重新登录",
                  "系统提示",
                  {
                    confirmButtonText: "重新登录",
                    cancelButtonText: "取消",
                    type: "warning"
                  }
              ).then(() => {
                router.push({
                  path: "/login"
                });
              });
            } else if (code != 200) {
                Notification.error({
                    title: msg
                });
            }
        } else {
            Message({
                type: "error",
                message: "连接服务器失败",
                duration: 3000
            });
        }
        return Promise.reject(error.response);
    }
);

export default instance;
