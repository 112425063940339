import login from "./login";

export default {
  defaultTimeout: 400000,
  apiUrl: {
    ...login,
    authorization: 'sam_lic/business/comUser/list',
    add: 'sam_lic/business/comUser/add', // 授权中心-添加
    queryById: 'sam_lic/business/comUser/queryById', // 授权中心-通过id查询
  }
};
