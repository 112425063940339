<template>
    <div class="app-wrapper" :class="classObj">
        <div
                v-if="device === 'mobile' && sidebar.opened"
                class="drawer-bg"
                @click="handleClickOutside"
        />
        <!--左侧导航栏-->
        <Sidebar class="sidebar-container"></Sidebar>

        <div :class="{ hasTagsView: needTagsView }" class="main-container">
            <div :class="{ 'fixed-header': fixedHeader }">
                <navbar></navbar>
                <tags-view v-if="needTagsView"/>
            </div>
            <app-main></app-main>
        </div>
    </div>
</template>

<script>
	import {mapGetters, mapState} from "vuex";
	import Hamburger from "@/components/Hamburger/index";
	import Sidebar from "./components/Sidebar/index";
	import Navbar from "./components/Navbar";
	import TagsView from "./components/TagsView/index";
	import AppMain from "./components/AppMain";

	export default {
		name: "index",
		components: {
			Sidebar,
			Hamburger,
			Navbar,
			TagsView,
			AppMain
		},
		computed: {
			...mapGetters(["sidebar"]),
			...mapState({
				showSettings: state => state.settings.showSettings,
				needTagsView: state => state.settings.tagsView,
				fixedHeader: state => state.settings.fixedHeader,
				device: state => state.app.device
			}),
			isCollapse() {
				return !this.sidebar.opened;
			},
			classObj() {
				return {
					hideSidebar: !this.sidebar.opened,
					openSidebar: this.sidebar.opened,
					withoutAnimation: this.sidebar.withoutAnimation,
					mobile: this.device === "mobile"
				};
			}
		},
		methods: {
			toggleSideBar() {
				this.$store.dispatch("app/toggleSideBar");
			},
			handleClickOutside() {
				this.$store.dispatch("app/closeSideBar", {withoutAnimation: false});
			}
		}
	};
</script>

<style lang="scss" scoped>
    @import "~@/assets/styles/mixin.scss";
    @import "~@/assets/styles/variables.scss";

    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;

        &.mobile.openSidebar {
            position: fixed;
            top: 0;
        }
    }

    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .fixed-header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: calc(100% - #{$sideBarWidth});
        transition: width 0.28s;
    }

    .hideSidebar .fixed-header {
        width: calc(100% - 54px);
    }

    .mobile .fixed-header {
        width: 100%;
    }

    .navbar {
        height: 50px;
        overflow: hidden;
        position: relative;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

        .hamburger-container {
            line-height: 46px;
            height: 100%;
            float: left;
            cursor: pointer;
            transition: background 0.3s;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                background: rgba(0, 0, 0, 0.025);
            }
        }

        .breadcrumb-container {
            float: left;
        }

        .errLog-container {
            display: inline-block;
            vertical-align: top;
        }

        .right-menu {
            float: right;
            height: 100%;
            line-height: 50px;

            &:focus {
                outline: none;
            }

            .right-menu-item {
                display: inline-block;
                padding: 0 8px;
                height: 100%;
                font-size: 18px;
                color: #5a5e66;
                vertical-align: text-bottom;

                &.hover-effect {
                    cursor: pointer;
                    transition: background 0.3s;

                    &:hover {
                        background: rgba(0, 0, 0, 0.025);
                    }
                }
            }

            .avatar-container {
                margin-right: 30px;

                .avatar-wrapper {
                    margin-top: 5px;
                    position: relative;

                    .user-avatar {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        border-radius: 10px;
                    }

                    .el-icon-caret-bottom {
                        cursor: pointer;
                        position: absolute;
                        right: -20px;
                        top: 25px;
                        font-size: 12px;
                    }
                }
            }
        }

        .hideSidebar .fixed-header {
            width: calc(100% - 54px);
        }
    }
</style>
