import { setToken, getToken } from "@/util/token";

const user = {
  state: {
    token: getToken('token') || null,
    userInfo: getToken("userInfo") || null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
    	state.token = token;
    	setToken('token', token);
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data;
      setToken("userInfo", data);
    }
  },

  actions: {
    setToken ({ commit }, data) {
    	commit('SET_TOKEN', data);
    },
    setUserInfo({ commit }, data) {
      commit("SET_USERINFO", data);
    }
  }
};

export default user;
