import instance from "./instance";
import qs from "qs";

/**
 * axios get
 * @param url 地址
 * @param params 参数
 * @returns {Promise<unknown>}
 */
export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};

/**
 * axios post请求
 * @param url 地址
 * @param params 参数 (url)
 * @param data 参数 (body体)
 * @returns {Promise<unknown>}
 */
export const post = (url, data = {}, params) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url: url,
      data: data,
      params: params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};

/**
 * axios post请求
 * @param url 地址
 * @param params 参数 (url)
 * @param data 参数 (body体)
 * @returns {Promise<unknown>}
 */
export const postForm = (url, data = {}, params) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url: url,
      data: qs.stringify(data),
      params: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      }
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};

/**
 * axios post请求
 * @param url 地址
 * @param params 参数 (url)
 * @param data 参数 (body体)
 * @returns {Promise<unknown>}
 */
export const postNoToken = (url, data = {}, params) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url: url,
      data: data,
      params: params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};

/**
 * axios put请求
 * @param url 地址
 * @param data 参数 (body体)
 * @param params 参数 (url)
 * @returns {Promise<any>}
 */
export const put = (url, params, data = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "put",
      url: url,
      params: params,
      data: data,
      headers: {
        authorization: `Bearer`
      }
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};

/**
 * axios Delete请求
 * @param url 地址
 * @param data 参数 (body体)
 * @param params 参数 (url)
 * @returns {Promise<any>}
 */
export const Delete = (url, params, data = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "delete",
      url: url,
      params: params,
      data: data,
      headers: {
        authorization: `Bearer`
      }
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
};
